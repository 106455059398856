html, body, #root, .App {
  height: 100%; 
}

.loadingPage
{
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page
{
  /* padding: 25px 0px 100px 0px; */
  background-color: #f0f2f5;
  /* max-width: 850px; */
  margin: auto;
  min-height: 100vh;
  line-height: 1.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
}
.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}